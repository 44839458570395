import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;

  gap: 5px;

  align-items: center;
  justify-content: center;
`;

export const ButtonSearch = styled.button`
  width: 200px;
  height: 34px;

  border-radius: 1rem;
  border: 2px solid var(--red-color);
`;

export const Input = styled.input``;

export const SerchArea = styled.div`
  input {
    width: 300px;
    height: 34px;

    text-align: left;

    border-radius: 1rem;
    border: 2px solid var(--red-color);

    padding-left: 15px;
    background-color: #f3f3f3;
  }

  svg {
    cursor: pointer;
  }
`;
