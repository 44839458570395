import { useState, useEffect } from "react";
import {
  Background,
  Container,
  ContentForm,
  Div,
  Titulo,
  Form,
  FormController,
  DivBotao,
  Label,
  FormEsquerda,
  Hr,
  // Select,
} from "./style.js";
import api from "../../services/api";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import InputMask from "react-input-mask";
import "../Login/inputMask.css";
import "./confirm.css";
import { IoSync, IoTrashOutline } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.css";

const ConfirmData = () => {
  const history = useHistory();
  const [cpf_doador, setCpf_doador] = useState("");
  const [nome, setNome] = useState("");
  const [data_nasc, setData_nasc] = useState("");
  // const [sexo, setSexo] = useState("");
  const [cep, setCep] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [agendamentoMultiplo, setAgendamentoMultiplo] = useState(false);
  const [receberEmail, setReceberEmail] = useState(true);

  const search = useLocation().search;
  const cpf = new URLSearchParams(search).get("cpf");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (cpf_doador.indexOf("_") === -1 && cpf_doador !== "") {
      try {
        const response = await api.get(`/admin/users/?cpf=${cpf_doador}`);
        if (response.data.doadores.length === 0) {
          return swal("Atenção", "Cpf inexistente!", "warning");
        }
        if (response.data.error) {
          return swal("Atenção!", response.data.error, "warning");
        }
        setNome(response.data.doadores[0].nome);
        setData_nasc(response.data.doadores[0].data_nasc);
        // setSexo(response.data.doadores[0].sexo);
        setEmail(response.data.doadores[0].email);
        setTelefone(response.data.doadores[0].telefone);
        setCep(response.data.doadores[0].cep);
        setNumero(
          response.data.doadores[0].numero
            ? response.data.doadores[0].numero
            : ""
        );
        setComplemento(
          response.data.doadores[0].complemento
            ? response.data.doadores[0].complemento
            : ""
        );
        setReceberEmail(
          response.data.doadores[0].receber_emails === 0 ? false : true
        );
        setAgendamentoMultiplo(
          response.data.doadores[0].agendamento_multiplo ? true : false
        );
        document.getElementById("cep").focus();
        document.getElementById("numero").focus();
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          history.push({
            pathname: "/",
          });
        }
      }
    } else {
      setNome("");
      setEmail("");
      setData_nasc("");
      setTelefone("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf_doador]);

  useEffect(() => {
    if (cpf) {
      setCpf_doador(cpf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      cpf: cpf_doador,
      nome: nome.toUpperCase(),
      data_nasc: data_nasc,
      // sexo: sexo,
      cep: cep,
      email: email,
      telefone: telefone,
      complemento: complemento,
      cidade: cidade,
      numero: numero,
      bairro: bairro,
      uf: estado,
      logradouro: logradouro,
      agendamento_multiplo: agendamentoMultiplo,
      receber_emails: receberEmail,
    };

    try {
      const response = await api.patch(`/update`, { data });
      if (response.data.error)
        return swal("Atenção!", response.data.error, "warning");
      swal("Sucesso!", "Dados atualizados", "success").then((response) => {
        clearInputs();
        document.getElementById("cpf").focus();
      });
    } catch (error) {
      swal("Atenção!", "Serviço indisponível no momento", "error");
    }
  }

  function clearInputs() {
    setCpf_doador("");
    setNome("");
    setData_nasc("");
    // setSexo("");
    setCep("");
    setEmail("");
    setTelefone("");
    setLogradouro("");
    setComplemento("");
    setCidade("");
    setNumero("");
    setBairro("");
    setEstado("");
    setAgendamentoMultiplo(false);
  }

  function onBlurCep(ev) {
    const { value } = ev.target;
    const cep = value.replace(/[^0-9]/g, "");
    if (cep.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
        setCidade(data.localidade);
        setEstado(data.uf);
      });
    // document.getElementById('numero').focus();
  }

  return (
    <>
      <Background>
        <Container>
          <ContentForm>
            <Titulo>
              <legend>Atualização do cadastro:</legend>
            </Titulo>
            <Form>
              <FormEsquerda>
                <h5>Informações Pessoais</h5>
                <Hr />
                <FormController
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "0.1fr 0.9fr",
                    gridTemplateRows: "1fr",
                    alignItems: "center",
                  }}
                >
                  <InputMask
                    type="checkbox"
                    id="agendamento"
                    name="agendamento"
                    checked={agendamentoMultiplo}
                    onChange={(e) => setAgendamentoMultiplo(e.target.checked)}
                  />
                  <Label
                    style={{
                      lineHeight: "18px",
                      textAlign: "center",
                    }}
                    htmlFor="agendamento"
                  >
                    Permisão para múltiplos agendamentos
                  </Label>
                </FormController>
                <FormController
                  className="checkbox"
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "0.1fr 0.9fr",
                    gridTemplateRows: "1fr",
                    alignItems: "center",
                  }}
                >
                  <InputMask
                    type="checkbox"
                    id="lembrete"
                    name="lembrete"
                    checked={receberEmail}
                    onChange={(e) => setReceberEmail(e.target.checked)}
                  />
                  <Label
                    htmlFor="lembrete"
                    style={{
                      lineHeight: "18px",
                      textAlign: "center",
                    }}
                  >
                    Desejo receber lembretes por e-mail
                  </Label>
                </FormController>
                <FormController>
                  <Label>CPF:</Label>
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    value={cpf_doador}
                    onChange={(e) => setCpf_doador(e.target.value)}
                    id="cpf"
                    required
                    className="inputMask"
                  />
                </FormController>
                <FormController>
                  <Label>Nome:</Label>
                  <Div
                    type="text"
                    value={nome.toUpperCase()}
                    required
                    pattern="([A-ZÀ-Ú]* [A-ZÀ-Ú ]*$)"
                    title="Informe pelo menos um sobrenome"
                    onChange={(e) => setNome(e.target.value)}
                  />
                </FormController>
                <FormController>
                  <Label>Data Nasc:</Label>
                  <InputMask
                    style={{ marginTop: 0, textAlign: "center" }}
                    mask="99/99/9999"
                    type="text"
                    value={data_nasc}
                    required
                    pattern="(?:((?:0[1-9]|1[0-9]|2[0-9])\/(?:0[1-9]|1[0-2])|(?:30)\/(?!02)(?:0[1-9]|1[0-2])|31\/(?:0[13578]|1[02]))\/(?:19|20)[0-9]{2})"
                    onChange={(e) => setData_nasc(e.target.value)}
                    className="Input2"
                  />
                </FormController>
                {/* <FormController>
                  <Label>Sexo Biológico:</Label>
                  <Select
                    required
                    value={sexo}
                    onChange={(e) => setSexo(e.target.value)}
                    title="Sexo Biológico (para fins de compatibilidade de doação)"
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </Select>
                </FormController> */}
                <FormController>
                  <Label>Email:</Label>
                  <Div
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormController>
                <FormController>
                  <Label>Cel:</Label>
                  <InputMask
                    style={{ marginTop: 0 }}
                    type="text"
                    required
                    mask="(99) 99999-9999"
                    pattern="([^0-9]{0,}([0-9]{2})[^0-9]{0,}([0-9]{5})[^0-9]{0,}([0-9]{4}).*)"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                    className="Input2"
                  />
                </FormController>
                <h5 style={{ marginTop: 30 }}>Localização</h5>
                <Hr />
                <FormController>
                  <Label>CEP:</Label>
                  <InputMask
                    style={{ marginTop: 0 }}
                    type="text"
                    mask="99999-999"
                    value={cep}
                    required
                    pattern="\d{5}-?\d{3}"
                    onChange={(e) => setCep(e.target.value)}
                    className="Input2"
                    id="cep"
                    onFocus={onBlurCep}
                    onBlur={onBlurCep}
                  />
                </FormController>
                <FormController>
                  <Label>Logradouro (Rua/Av.): </Label>
                  <Div
                    type="text"
                    value={logradouro}
                    onChange={(e) => setLogradouro(e.target.value)}
                    id="logradouro"
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Número:</Label>
                  <Div
                    type="text"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                    id="numero"
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Cidade:</Label>
                  <Div
                    type="text"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Bairro:</Label>
                  <Div
                    type="text"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Estado:</Label>
                  <Div
                    type="text"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Complemento:</Label>
                  <Div
                    type="text"
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                    required
                  />
                </FormController>
                <DivBotao>
                  <button
                    type="button"
                    className="botao"
                    style={{ fontSize: 14, marginRight: 20 }}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Atualizar&nbsp;
                    <IoSync
                      size={18}
                      style={{ fontWeight: "bolder", MarginLeft: "57px" }}
                    />
                  </button>
                  <button
                    className="botao"
                    style={{ fontSize: 14, marginLeft: 20 }}
                    type={"button"}
                    onClick={() => {
                      if (cpf_doador.indexOf("_") === -1 && cpf_doador !== "") {
                        swal2
                          .fire({
                            title: "Tem certeza?",
                            text: "Este doador será excluído permanentemente!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Excluir",
                            cancelButtonText: "Cancelar",
                          })
                          .then(async (result) => {
                            if (result.isConfirmed) {
                              await api.delete(`admin/users/${cpf_doador}`);
                              swal2.fire(
                                "Sucesso!",
                                "Doador excluído com sucesso!",
                                "success"
                              );
                              clearInputs();
                              document.getElementById("cpf").focus();
                            }
                          });
                      }
                    }}
                  >
                    Excluir&nbsp;
                    <IoTrashOutline
                      size={18}
                      style={{ fontWeight: "bolder", MarginLeft: "57px" }}
                    />
                  </button>
                </DivBotao>
              </FormEsquerda>
              {/* <FormDireita>
                                <Link to={"/SelectTime"}>Inserir Doação</Link>
                            </FormDireita> */}
            </Form>
          </ContentForm>
        </Container>
      </Background>
    </>
  );
};
export default ConfirmData;
