import React from "react";
import { Container, Input, ButtonSearch, SerchArea } from "./styles";
import { IoMdClose } from "react-icons/io";
import InputMask from "react-input-mask";
import "./styles.css";

export default function Switch({
  text1,
  text2,
  text3,
  selected,
  setSelected,
  placeholder1,
  placeholder2,
  placeholder3,
  search,
  setSearch,
}) {
  const handleButtonClick = (index) => {
    setSelected(index);
    setSearch("");
  };

  return (
    <Container>
      <ButtonSearch
        onClick={() => handleButtonClick(0)}
        style={{
          color: selected === 0 ? "white" : "black",
          backgroundColor: selected === 0 ? "var(--red-color)" : "#f3f3f3",
        }}
      >
        {text1}
      </ButtonSearch>
      <ButtonSearch
        onClick={() => handleButtonClick(1)}
        style={{
          color: selected === 1 ? "white" : "black",
          backgroundColor: selected === 1 ? "var(--red-color)" : "#f3f3f3",
        }}
      >
        {text2}
      </ButtonSearch>

      <ButtonSearch
        onClick={() => handleButtonClick(2)}
        style={{
          color: selected === 2 ? "white" : "black",
          backgroundColor: selected === 2 ? "var(--red-color)" : "#f3f3f3",
        }}
      >
        {text3}
      </ButtonSearch>
      <SerchArea>
        {selected === 0 ? (
          <InputMask
            type="text"
            mask="999.999.999-99"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder1}
          />
        ) : selected === 1 ? (
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder2}
          />
        ) : (
          <Input
            type="email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder3}
          />
        )}
        <IoMdClose color="red" onClick={() => setSearch("")} />
      </SerchArea>
    </Container>
  );
}
