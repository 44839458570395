import React, { useState, useEffect, useCallback } from "react";
import {
  Background,
  Content,
  Button,
  Form,
  ButtonNewDonor,
  // SelectContainer,
  InputCheckboxContainer,
} from "./style";
import api, { baseURL } from "../../services/api";
import "./inputMask.css";
import Swal from "sweetalert2";
import Auth from "../../services/auth";
import ModalPlanilha from "../../Components/Modal/Doadores";
import TableDoadores from "../../Components/Tables/TableDoadores";
import Pagination from "../../Components/Pagination";
import Switch from "./Switch";
import Modal from "../../Components/Modal/Modal";
import InputLabel from "../../Components/InputLabel";
import CalculaIdade from "../../services/CalculaData";
import CPF from "cpf-check";
import swal from "sweetalert";

const Doadores = () => {
  const [cpfSearch, setCpfSearch] = useState("");
  const [nomeSearch, setNomeSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [showModalPlanilha, setShowModalPlanilha] = useState(false);
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [auth] = useState(Auth.auth());
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [data_nasc, setData_nasc] = useState("");
  // const [sexo, setSexo] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [receberEmail, setReceberEmail] = useState(true);

  useEffect(() => {
    if (selected === 0) {
      setCpfSearch(search);
      setNomeSearch("");
      setEmailSearch("");
    } else if (selected === 1) {
      setCpfSearch("");
      setNomeSearch(search);
      setEmailSearch("");
    } else {
      setNomeSearch("");
      setCpfSearch("");
      setEmailSearch(search);
    }
  }, [search, selected]);

  const fetchDoadores = useCallback(async () => {
    try {
      const response = await api.get(`/admin/users/?filtro=true`);
      setData(response.data.doadores);
      setDataFiltered(response.data.doadores);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setPage(1);
    fetchDoadores();
  }, [fetchDoadores]);

  useEffect(() => {
    setPage(1);

    const filteredData = data.filter((item) => {
      const matchNome = nomeSearch
        ? item.nome.toLowerCase().includes(nomeSearch.toLowerCase())
        : true;

      const matchCpf = cpfSearch ? item.cpf.includes(cpfSearch) : true;

      const matchEmail = emailSearch
        ? item.email.toLowerCase().includes(emailSearch.toLowerCase())
        : true;

      return matchNome && matchCpf && matchEmail;
    });

    setDataFiltered(filteredData);
  }, [nomeSearch, cpfSearch, emailSearch, data]);

  useEffect(() => {
    setTotalPages(Math.ceil(dataFiltered.length / 10));
  }, [dataFiltered]);
  /**
   * Vai servir para gerar a planilha .xlsx do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();

    Swal.fire({
      title: "Escolha uma das opções",
      text: "Qual planilha deseja baixar?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonColor: "var(--red-color)",
      denyButtonColor: "var(--red-color)",
      confirmButtonText: "Histórico de doações",
      denyButtonText: "Todos os usuários",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Aguarde!",
          text: "O relatório está sendo gerado!",
          icon: "info",
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          await api
            .post(
              `/admin/generatexlsx/${
                selected === 0
                  ? `?nomeSearch=${nomeSearch}&filtro=true`
                  : `${cpfSearch}?filtro=true`
              }`
            )
            .then((response) => {
              if (response.data.error)
                return Swal.fire("Atenção", response.data.error, "warning");
              window.open(`${baseURL}/doadores?auth=${auth}`);
              Swal.close();
            });
        } catch (error) {
          Swal.fire("Erro", "Erro ao gerar relatório", "error");
        }
      } else if (result.isDenied) {
        Swal.fire({
          title: "Aguarde!",
          text: "O relatório está sendo gerado!",
          icon: "info",
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          await api.post(`/admin/todosusuarios/`).then((response) => {
            if (response.data.error)
              return Swal.fire("Atenção", response.data.error, "warning");
            window.open(`${baseURL}/todosusuarios?auth=${auth}`);
            Swal.close();
          });
        } catch (error) {
          Swal.fire("Erro", "Erro ao gerar relatório", "error");
        }
      }
    });
  }

  function onBlurCep(ev) {
    const { value } = ev.target;
    const cep = value.replace(/[^0-9]/g, "");
    if (cep.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
        setCidade(data.localidade);
        setEstado(data.uf);
      });
    // document.getElementById('numero').focus();
  }

  const d = new Date();
  let ano = d.getFullYear();
  ano -= 16;

  async function handleSubmitModal(e) {
    e.preventDefault();

    if (
      CalculaIdade(
        data_nasc.split("/")[2] +
          "-" +
          data_nasc.split("/")[1] +
          "-" +
          data_nasc.split("/")[0]
      ) < 16
    ) {
      return Swal.fire({
        title: "Data inválida!",
        text: "Para se cadastrar é necessario ter pelo menos 16 anos!",
        icon: "warning",
      });
    }
    if (data_nasc.split("/")[2] < ano - 84) {
      return Swal.fire({
        title: "Data inválida!",
        text: "Verifique a data e tente novamente!",
        icon: "warning",
      });
    }

    if (CPF.validate(cpf) === false) {
      return Swal.fire({
        title: "CPF inválido!",
        text: "O cpf não foi reconhecido",
        icon: "warning",
      });
    }

    if (senha !== confirmSenha) {
      return swal("Atenção!", "As senhas não estão iguais", "warning");
    }

    const data = {
      cpf: cpf,
      nome: nome,
      data_nasc: data_nasc,
      cep: cep,
      email: email,
      telefone: telefone,
      senha: senha,
      // sexo: sexo,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      uf: estado,
      numero: numero,
      logradouro: logradouro,
      receber_emails: receberEmail,
    };

    try {
      const response = await api.post("/register", { data });
      if (response.data.error) {
        return Swal.fire({
          title: "Alerta!",
          text: response.data.error,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }

      Swal.fire({
        title: "Sucesso!",
        text: response.data.success,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      Swal.fire({
        title: "Alerta!",
        text: "Serviço indisponível no momento",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  return (
    <Background>
      <Content>
        <h1 style={{ fontWeight: "bolder" }}> Doadores Cadastrados </h1>
        <hr />
        <Form onSubmit={handleSubmit}>
          <Button>
            <button type="submit">Baixar Planilha</button>
          </Button>
        </Form>
        <div>
          <Switch
            text1="CPF"
            text2="Nome"
            text3="E-mail"
            placeholder1="Insira o CPF"
            placeholder2="Insira o Nome"
            placeholder3="Insira o E-mail"
            search={search}
            setSearch={setSearch}
            selected={selected}
            setSelected={setSelected}
          />
          <ButtonNewDonor onClick={() => setIsOpen(true)}>
            Novo Doador
          </ButtonNewDonor>
        </div>
        <TableDoadores data={dataFiltered.slice((page - 1) * 10, page * 10)} />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Content>
      <ModalPlanilha
        showModal={showModalPlanilha}
        setShowModal={setShowModalPlanilha}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={() => setIsOpen(false)}
      >
        <h1>Novo</h1>
        <InputLabel
          type="text"
          label="Nome"
          pattern="([A-ZÀ-Ú]* [A-ZÀ-Ú ]*$)"
          title="Informe pelo menos um sobrenome"
          required
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
        <InputLabel
          type="text"
          label="CPF"
          mask="999.999.999-99"
          required
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
        <InputLabel
          type="text"
          label="Data de Nascimento"
          mask="99/99/9999"
          pattern="(?:((?:0[1-9]|1[0-9]|2[0-9])\/(?:0[1-9]|1[0-2])|(?:30)\/(?!02)(?:0[1-9]|1[0-2])|31\/(?:0[13578]|1[02]))\/(?:19|20)[0-9]{2})"
          required
          value={data_nasc}
          onChange={(e) => setData_nasc(e.target.value)}
        />
        {/* <SelectContainer>
          <label htmlFor="sexo-biologico">Sexo Biológico</label>
          <select
            id="sexo-biologico"
            required
            value={sexo}
            onChange={(e) => setSexo(e.target.value)}
            title="Sexo Biológico (para fins de compatibilidade de doação)"
          >
            <option value="">Selecione uma opção</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </SelectContainer> */}
        <InputLabel
          type="text"
          label="E-mail"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputLabel
          type="password"
          label="Senha"
          required
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
        <InputLabel
          type="password"
          label="Confirmar Senha"
          required
          value={confirmSenha}
          onChange={(e) => setConfirmSenha(e.target.value)}
        />
        <InputLabel
          type="text"
          label="Telefone"
          mask="(99) 99999-9999"
          pattern="([^0-9]{0,}([0-9]{2})[^0-9]{0,}([0-9]{5})[^0-9]{0,}([0-9]{4}).*)"
          required
          value={telefone}
          onChange={(e) => setTelefone(e.target.value)}
        />
        <InputLabel
          type="text"
          label="CEP"
          mask="99999-999"
          pattern="\d{5}-?\d{3}"
          required
          value={cep}
          onChange={(e) => setCep(e.target.value)}
          onBlur={onBlurCep}
          onFocus={onBlurCep}
        />
        <InputLabel
          type="text"
          label="Numero"
          required
          value={numero}
          onChange={(e) => setNumero(e.target.value)}
        />
        <InputLabel
          type="text"
          label="Logradouro"
          required
          value={logradouro}
          onChange={(e) => setLogradouro(e.target.value)}
        />
        <InputLabel
          type="text"
          label="Cidade"
          required
          value={cidade}
          onChange={(e) => setCidade(e.target.value)}
        />
        <InputLabel
          type="text"
          label="Bairro"
          required
          value={bairro}
          onChange={(e) => setBairro(e.target.value)}
        />
        <InputLabel
          type="text"
          label="Estado"
          required
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
        />
        <InputLabel
          type="text"
          label="Complemento (Opcional)"
          value={complemento}
          onChange={(e) => setComplemento(e.target.value)}
        />
        <InputCheckboxContainer>
          <input
            type="checkbox"
            id="lembrete"
            name="lembrete"
            value={receberEmail}
            onChange={(e) => setReceberEmail(e.target.checked)}
          />
          <label htmlFor="lembrete">
            Receber lembretes por e-mail sobre as próximas datas de doação
          </label>
        </InputCheckboxContainer>
        <ButtonNewDonor onClick={handleSubmitModal}>Cadastrar</ButtonNewDonor>
      </Modal>
    </Background>
  );
};

export default Doadores;
