import React, { useState, useEffect, useCallback } from "react";
import {
  Background,
  Content,
  Button,
  FiltersContainer,
  Input,
  ModalContentContainer,
} from "./style";
import api, { baseURL } from "../../services/api";
import "./inputMask.css";
import Swal from "sweetalert2";
import Auth from "../../services/auth";
import Switch from "../../Components/Switch";
import Pagination from "../../Components/Pagination";
import TableSendingEmails from "../../Components/Tables/TableSendingEmails";
import Modal from "../../Components/Modal/Modal";
import ToggleSwitch from "../../Components/ToogleSwitch";

const SendingEmails = () => {
  const [auth] = useState(Auth.auth());
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(0);

  const [cpfSearch, setCpfSearch] = useState("");
  const [nomeSearch, setNomeSearch] = useState("");

  const [idCalendario, setIdCalendario] = useState(null);
  const [calendarios, setCalendarios] = useState([]);

  const [dateSendingEmails, setDateSendingEmails] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [statusSendingEmails, setStatusSendingEmails] = useState(false);

  const fetchCalendars = useCallback(async () => {
    api
      .get(`/calendars`)
      .then((response) => {
        setCalendarios(response?.data?.calendarios);
        setIdCalendario(response?.data?.calendarios[0]?.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchCalendars();
  }, [fetchCalendars]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(
        `/donor-reminders?id_calendario=${idCalendario}`
      );
      setData(response?.data);
      setDataFiltered(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [idCalendario]);

  const fetchDateSendingEmails = useCallback(async () => {
    setDateSendingEmails(null);
    try {
      const response = await api.get(`/buscar_envio_emails/${idCalendario}`);

      if (response?.data?.data_inicio) {
        setDateSendingEmails(response?.data);
        setDateStart(response?.data?.data_inicio);
        setDateEnd(response?.data?.data_termino);
        setStatusSendingEmails(response?.data?.envio_habilitado === 1);
      } else {
        setDateSendingEmails(null);
        setDateStart(null);
        setDateEnd(null);
        setStatusSendingEmails(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [idCalendario]);

  useEffect(() => {
    fetchData();
    fetchDateSendingEmails();
  }, [fetchData, fetchDateSendingEmails]);

  useEffect(() => {
    if (selected === 0) {
      setCpfSearch(search);
      setNomeSearch("");
    } else {
      setCpfSearch("");
      setNomeSearch(search);
    }
  }, [search, selected]);

  useEffect(() => {
    setPage(1);

    const filteredData = data.filter((item) => {
      const matchNome = nomeSearch
        ? item.nome.toLowerCase().includes(nomeSearch.toLowerCase())
        : true;

      const matchCpf = cpfSearch ? item.cpf.includes(cpfSearch) : true;

      return matchNome && matchCpf;
    });

    setDataFiltered(filteredData);
  }, [data, nomeSearch, cpfSearch]);

  useEffect(() => {
    setTotalPages(Math.ceil(dataFiltered.length / 10));
  }, [dataFiltered]);

  const handleUpdateStatusSendingEmails = async () => {
    try {
      await api.put(`/atualizar_envio_emails/${dateSendingEmails?.id}`, {
        envio_habilitado: statusSendingEmails === true ? "0" : "1",
      });
      setIsOpenModal(false);
      fetchDateSendingEmails();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateSendingEmails = async () => {
    if (!dateStart || !dateEnd) {
      return;
    }

    try {
      await api.put(`/atualizar_envio_emails/${dateSendingEmails?.id}`, {
        data_inicio: dateStart,
        data_termino: dateEnd,
        envio_habilitado: statusSendingEmails === true ? "1" : "0",
      });
      setIsOpenModal(false);
      fetchDateSendingEmails();
    } catch (error) {
      console.log(error);
    }
  };

  async function handleClick(e) {
    e.preventDefault();

    Swal.fire({
      title: "Deseja baixar a Planilha?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonColor: "var(--red-color)",
      denyButtonColor: "var(--red-color)",
      confirmButtonText: "Sim",
      denyButtonText: "Não",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Aguarde!",
          text: "O relatório está sendo gerado!",
          icon: "info",
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          await api.get(`/envio_emails/xlsx`).then((response) => {
            if (response.data.error)
              return Swal.fire("Atenção", response.data.error, "warning");
            window.open(`${baseURL}/envio_emails?auth=${auth}`);
            Swal.close();
          });
        } catch (error) {
          Swal.fire("Erro", "Erro ao gerar relatório", "error");
        }
      }
    });
  }

  return (
    <Background>
      <Content>
        {new Date(dateSendingEmails?.inicio_doacao) <= new Date() &&
        new Date(dateSendingEmails?.termino_doacao) >= new Date() ? (
          <div>
            <h1 style={{ fontWeight: "bolder" }}> Envio de Emails </h1>
            <div>
              <ToggleSwitch
                isActive={statusSendingEmails}
                onToggle={() => {
                  handleUpdateStatusSendingEmails();
                }}
              />
              {statusSendingEmails === true ? (
                <div>
                  <label>Inicio</label>
                  <Input
                    type="date"
                    disabled={true}
                    placeholder="Data de Inicio"
                    value={dateSendingEmails?.data_inicio?.split("T")[0]}
                  />
                  <label>Termino</label>
                  <Input
                    type="date"
                    disabled={true}
                    placeholder="Data de Termino"
                    value={dateSendingEmails?.data_termino?.split("T")[0]}
                  />
                </div>
              ) : (
                <h2>Envio de Emails Desabilitado</h2>
              )}
              <button
                onClick={() => {
                  setDateStart(dateSendingEmails?.data_inicio?.split("T")[0]);
                  setDateEnd(dateSendingEmails?.data_termino?.split("T")[0]);
                  setStatusSendingEmails(
                    dateSendingEmails?.envio_habilitado === 1 ? true : false
                  );
                  setIsOpenModal(true);
                }}
              >
                Editar
              </button>
            </div>
            <Button onClick={handleClick}>Baixar Planilha</Button>
          </div>
        ) : (
          <div>
            <h1 style={{ fontWeight: "bolder" }}> Envio de Emails </h1>
            <Button onClick={handleClick}>Baixar Planilha</Button>
          </div>
        )}
        <FiltersContainer>
          <select
            autoFocus
            className="select-calendar"
            name="select-calendario"
            id="select-calendario"
            onChange={(e) => setIdCalendario(e.target.value)}
          >
            {calendarios?.map((calendario) => (
              <option key={calendario?.id} value={calendario?.id}>
                {calendario?.data_doacao}
              </option>
            ))}
          </select>
          <Switch
            text1="CPF"
            text2="Nome"
            placeholder1="Insira o CPF"
            placeholder2="Insira o Nome"
            search={search}
            setSearch={setSearch}
            selected={selected}
            setSelected={setSelected}
          />
        </FiltersContainer>
        <TableSendingEmails
          data={dataFiltered.slice((page - 1) * 10, page * 10)}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Content>
      <Modal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={() => {}}
      >
        <h2>Editar Envio de Emails</h2>
        <ModalContentContainer>
          <label>Inicio</label>
          <Input
            type="date"
            placeholder="Data de Inicio"
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
          />
          <label>Termino</label>
          <Input
            type="date"
            placeholder="Data de Termino"
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
          />

          <button onClick={() => handleUpdateSendingEmails()}>Salvar</button>
        </ModalContentContainer>
      </Modal>
    </Background>
  );
};

export default SendingEmails;
